<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Игрок</th>
              <th class="text-center">Баллы</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in displayItems" :key="item.id">
              <td
                class="text-left"
                :class="{ 'text-h5': item.index <= highlight }"
              >
                {{ item.index }}
              </td>
              <td
                class="text-left"
                style="text-transform: capitalize"
                :class="{ 'text-h5': item.index <= highlight }"
              >
                {{ item.name }}
              </td>
              <td
                class="text-center"
                :class="{ 'text-h5': item.index <= highlight }"
              >
                {{ item.points }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Advent2022Standings",
  props: ["items", "my-points", "my-game-id", "params"],
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
    }),
    itemsWithName() {
      return _.filter(this.items, (item) => !!item.name);
    },
    sortedItems() {
      return _.orderBy(
        this.itemsWithName,
        ["points", "name", "user"],
        ["desc", "asc", "asc"]
      );
    },
    displayItems() {
      return this.sortedItems.map((item, index) => {
        return {
          ...item,
          index: index + 1,
        };
      });
    },
    highlight() {
      return _.get(this.params, "standings.highlight") || 0;
    },
  },
  data() {
    return {};
  },
};
</script>
